import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { delay, map, tap } from 'rxjs/operators';
import {
  resetQueryAction,
  setQueryAction,
  setStateAction,
} from '../store/actions/admin-expert-system-module.actions';
import dayjs from 'dayjs';
import { injectQueryClient } from '@tanstack/angular-query-experimental';

@Injectable()
export class AdminExpertSystemModuleEffects {
  constructor(private actions$: Actions) {}
  queryClient = injectQueryClient();

  setQuery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setQueryAction),
      map((payload) => {
        return setStateAction({ ...payload });
        // return { type: 'success', successAction: payload.successAction };
      })
      // delay(100),
      // tap(({ successAction }) => {
      //   if (successAction) {
      //     successAction(this.queryClient);
      //   }
      // })
    )
  );

  resetQuery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resetQueryAction),
      map((payload) =>
        setStateAction({
          esId: '',
          minDate: '',
          maxDate: '',
        })
      )
      // switchMap(() => [setCardsAction(null), setListAction(null)])
    )
  );
}
