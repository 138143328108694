import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ToastService } from '../../core/services/toast.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrl: './auth.component.css',
  standalone: true,
  providers: [ToastService],
  imports: [RouterModule, CommonModule],
  host: {
    class: 'h-dvh block',
  },
})
export class AuthComponent {}
