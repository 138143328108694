import { Component, inject } from '@angular/core';
import { UserHeaderComponent } from '../user-header/user-header.component';
import { CommonModule } from '@angular/common';
import { NavMenuComponent } from '../nav-menu/nav-menu.component';
import { RouterModule } from '@angular/router';
import { InvoiceModal } from '../../invoice-modal/invoice-modal.component';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../core/store';
import { Subscription } from 'rxjs';
import { getSettingsStore } from '../../../core/store/selectors/settings.selectors';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { ScriptLoaderService } from '../../../core/services/script-loader.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-protected-layout',
  templateUrl: './protected-layout.component.html',
  styleUrl: './protected-layout.component.css',
  standalone: true,
  imports: [
    UserHeaderComponent,
    CommonModule,
    NavMenuComponent,
    RouterModule,
    InvoiceModal,
    NzLayoutModule,
    NzToolTipModule,
  ],
})
export class ProtectedLayoutComponent {
  store = inject(Store<AppState>);
  scriptLoader = inject(ScriptLoaderService);

  settingsStoreSub: Subscription;

  isCollapsed = true;

  ngOnInit(): void {
    this.settingsStoreSub = this.store
      .pipe(select(getSettingsStore))
      .subscribe((settingsStore) => {
        this.isCollapsed = settingsStore.sideMenuCollapsedState;
      });

    // Load the third-party script dynamically
    this.scriptLoader
      .loadScript(environment.remitaInlineUrl)
      .then(() => {
        // Access the global variable and function once the script has loaded
        console.log('Remita script loaded');
      })
      .catch((error) => {
        console.log('error :>> ', error);
        console.error('Error loading third-party script:', error);
      });
  }

  ngOnDestroy(): void {
    if (this.settingsStoreSub) this.settingsStoreSub.unsubscribe();
  }
}
