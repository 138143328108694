import {
  Component,
  effect,
  inject,
  Input,
  OnInit,
  signal,
  untracked,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';
import { HeaderComponent } from '../../../shared/widgets/header/header.component';
import { GeneralButtonDirective } from '../../../shared/directives/buttons/general-button.directive';
import {
  injectMutation,
  injectQuery,
} from '@tanstack/angular-query-experimental';
import { AuthService } from '../../../core/services/auth.service';
import { CommonModule } from '@angular/common';
import { Store } from '@ngrx/store';
import { AppState } from '../../../core/store';
import {
  errorToastAction,
  loadingToastAction,
  successToastAction,
} from '../../../core/store/actions/toast.actions';
import { SvgIconComponent } from 'angular-svg-icon';
import { VerificationService } from '../../../core/services/verification.service';

@Component({
  selector: 'app-email-verified',
  standalone: true,
  imports: [
    LottieComponent,
    HeaderComponent,
    GeneralButtonDirective,
    CommonModule,
    SvgIconComponent,
  ],
  templateUrl: './email-verified.component.html',
  styleUrl: './email-verified.component.css',
})
export class EmailVerifiedComponent implements OnInit {
  options: AnimationOptions = {
    path: '/assets/lottie/email_lottie_purple.json',
  };

  route = inject(ActivatedRoute);
  authService = inject(AuthService);
  verificationService = inject(VerificationService);
  router = inject(Router);
  store = inject(Store<AppState>);
  @Input() email: string;
  token = signal('');

  verifyEmailResponse = injectQuery(() => {
    return {
      queryKey: ['agency-list'],
      queryFn: () => this.authService.verifyEmail(this.token()),
      enabled: !!this.token(),
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: 0,
    };
  });

  resendEmailResponse = injectMutation(() => ({
    mutationFn: (data: { email: string }) =>
      this.verificationService.sendVerificationEmail(data),
    onSuccess: (data) => {
      this.store.dispatch(
        successToastAction({
          autohide: true,
          delay: 7000,
          placement: 'top-end',
          message: 'A new verification link has been sent to your email',
        })
      );
    },
    onError: (err: any) => {
      this.store.dispatch(
        errorToastAction({
          autohide: true,
          delay: 7000,
          placement: 'top-end',
          message:
            err?.error?.message ||
            'An error occurred while verifying email. Try again shortly',
        })
      );
    },
  }));

  onAnimate(animationItem: AnimationItem): void {}

  get isLoading() {
    return (
      this.verifyEmailResponse.isPending() &&
      this.verifyEmailResponse.fetchStatus() !== 'idle'
    );
  }

  constructor() {
    effect(() => {
      const error = this.verifyEmailResponse?.error();
      untracked(() => {
        if (error) {
          const errorMessage = (error as any)?.error?.message;

          this.store.dispatch(
            errorToastAction({
              autohide: true,
              delay: 7000,
              placement: 'top-end',
              message:
                errorMessage ||
                'An error occurred while verifying email. Try again shortly',
            })
          );
        }
      });
    });
  }
  ngOnInit(): void {
    this.token.set(this.route.snapshot.queryParamMap.get('token'));
  }

  get isError() {
    return this.verifyEmailResponse.isError();
  }

  switchToLogin() {
    this.router.navigate(['/'], {
      relativeTo: this.route,
      queryParams: {
        a: 'login',
      },
    });
  }

  resendEmail() {
    this.store.dispatch(
      loadingToastAction({
        autohide: true,
        delay: 7000,
        placement: 'top-end',
        message: 'Sending new verification email. Please wait...',
      })
    );
    this.resendEmailResponse.mutate({ email: this.email });
  }
}

//http://localhost:4200/verify_email?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InNlYmFzdGluZWNoaW1hQGdtYWlsLmNvbSIsInRva2VuIjoiQ2ZESjhLaXNJZzBnVC8xRXFkZEtTOXduOHp3ZTlDSi8zQlBFcEJ1WngwdVdnZkVxQk8vNVM4dmtWdzI5ZUxRVlUyY2VLalVYaHloS0JYNzFNVkFUNU1ReEZxVHFtc2JvRlBiN1hpdkxDN0I3R0ZycTFvdVIvRit2SmYxNm41Q2V1QlBpMWZjVTBNbGI4T3l1WjEzaUFOQ0hlZC9yRXEzRktmc2Mrd212dTJoQXZTUnFkMUJBK2RJdzNhaXBnMHd1RmpYL1lqeUw5N0hwYklaVjllTFl5Ukh1R2NQRmxSazBnOWF3Z0ZHV2xTZXlQYXR3enhCZEJEUzM0czJzSjY0V09NOUczQT09IiwibmJmIjoxNzE2MTMxMTc2LCJleHAiOjE3MTYxMzQ3NzYsImlhdCI6MTcxNjEzMTE3Nn0.FjOPqrs-ZsizCwqqUqjEp2raGj2kbMwUWKGRxf6eaT8
