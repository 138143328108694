import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { ToastCustomComponent } from './shared/widgets/toast/toast.component';
import { SpinnerComponent } from './shared/widgets/spinner/spinner.component';
import { NgxSpinnerModule } from 'ngx-spinner';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
  standalone: true,
  imports: [
    RouterModule,
    ToastCustomComponent,
    SpinnerComponent,
    NgxSpinnerModule,
  ],
})
export class AppComponent implements OnInit {
  title = 'PayKaduna';

  constructor(private router: Router) {}

  ngOnInit(): void {
    // Start all routes scroll position from top of page
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        document.querySelector('nz-content').scrollTop = 0;
      }
    });
  }
}
