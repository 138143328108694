import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { AppState } from '../../../core/store';
import { Subscription } from 'rxjs';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { getSettingsStore } from '../../../core/store/selectors/settings.selectors';
import { setStateAction } from '../../../core/store/actions/settings.actions';
import { RouterModule } from '@angular/router';
import { NgScrollbarModule } from 'ngx-scrollbar';

@Component({
  selector: 'app-public-side-drawer',
  standalone: true,
  imports: [
    CommonModule,
    NzMenuModule,
    NzButtonModule,
    NzGridModule,
    NzDrawerModule,
    RouterModule,
    NgScrollbarModule,
  ],
  templateUrl: './side-drawer.component.html',
  styleUrl: './side-drawer.component.css',
  host: {
    ngSkipHydration: 'true',
  },
})
export class SideDrawerComponent implements OnInit, OnDestroy {
  store = inject(Store<AppState>);

  @Input() visible: boolean = false;
  @Output() readonly onCloseTrigger = new EventEmitter<void>();

  settingsStoreSub: Subscription;

  onClose(): void {
    this.store.dispatch(setStateAction({ publicSideMenuVisible: false }));
    this.onCloseTrigger.emit();
  }

  ngOnInit(): void {
    this.settingsStoreSub = this.store
      .pipe(select(getSettingsStore))
      .subscribe((settingsStore) => {
        this.visible = settingsStore.publicSideMenuVisible;
      });
  }

  ngOnDestroy(): void {
    if (this.settingsStoreSub) this.settingsStoreSub.unsubscribe();
  }

  constructor() {}
}
