import { createReducer, on } from '@ngrx/store';
import { setStateAction } from '../actions/admin-esm-revenue-head-detail.actions';
import { Action } from '../../models';
import dayjs from 'dayjs';

export interface EsmRevenueHeadDetailReducerInitialState {
  maxDate: string;
  minDate: string;
  esId: string;
}

export const initialState: EsmRevenueHeadDetailReducerInitialState = {
  maxDate: '',
  minDate: '',
  esId: '',
};

const reducer = createReducer(
  initialState,
  on(setStateAction, (state, payload) => {
    return {
      ...state,
      ...payload,
    };
  })
);

export function esmRevenueHeadDetailReducerReducer(
  state: EsmRevenueHeadDetailReducerInitialState,
  action: Action
) {
  return reducer(state, action);
}
