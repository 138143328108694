import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from '../../shared/widgets/header/header.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { SideDrawerComponent } from '../../shared/widgets/side-drawer/side-drawer.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-public',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    HeaderComponent,
    ComingSoonComponent,
    SideDrawerComponent,
  ],
  templateUrl: './public.component.html',
  styleUrl: './public.component.css',
  host: {
    class: 'flex w-full h-full',
    // ngSkipHydration: 'true',
  },
})
export class PublicComponent {
  comingSoon = false;
}
