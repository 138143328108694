import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import {
  resetQueryAction,
  setQueryAction,
  setStateAction,
} from '../store/actions/admin-esm-revenue-head.actions';
import dayjs from 'dayjs';

@Injectable()
export class AdminEsmRevenueHeadEffects {
  constructor(private actions$: Actions) {}

  setQuery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setQueryAction),
      map((payload) => setStateAction({ ...payload }))
      // switchMap(() => [setCardsAction(null), setListAction(null)])
    )
  );

  resetQuery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resetQueryAction),
      map((payload) =>
        setStateAction({
          esId: '',
          minDate: '',
          maxDate: '',
        })
      )
      // switchMap(() => [setCardsAction(null), setListAction(null)])
    )
  );
}
