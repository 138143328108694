import { isDevMode } from '@angular/core';
import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer,
} from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { toastReducer, ToastState } from './reducers/toast.reducer';
import { lookupReducer, LookupState } from './reducers/lookup.reducer';
import { billReducer, BillState } from './reducers/bill.reducer';
import { userReducer, UserReducerInitialState } from './reducers/user.reducer';
import {
  adminDashboardReducer,
  AdminDashboardReducerInitialState,
} from './reducers/admin-dashboard.reducer';
import { roleReducer, RoleReducerInitialState } from './reducers/role.reducer';
import { decrypt, encrypt } from '../utils/crypt';
import {
  invoiceReducer,
  InvoiceReducerInitialState,
} from './reducers/invoice.reducer';
import {
  settingsReducer,
  SettingsReducerInitialState,
} from './reducers/settings.reducer';
import {
  invoiceReportReducerReducer,
  InvoiceReportReducerInitialState,
} from './reducers/admin-invoice-report.reducer';
import {
  analyticsReducerReducer,
  AnalyticsReducerInitialState,
} from './reducers/admin-analytics.reducer';
import {
  SettlementReportReducerInitialState,
  settlementReportReducerReducer,
} from './reducers/admin-settlement-report.reducer';
import {
  CollectionReportReducerInitialState,
  collectionReportReducerReducer,
} from './reducers/admin-collection-report.reducer';
import {
  MdaReportReducerInitialState,
  mdaReportReducerReducer,
} from './reducers/admin-mda-report.reducer';
import {
  ExpertSystemModuleReducerInitialState,
  expertSystemModuleReducerReducer,
} from './reducers/admin-expert-system-module.reducer';
import {
  EsmRevenueHeadReducerInitialState,
  esmRevenueHeadReducerReducer,
} from './reducers/admin-esm-revenue-head.reducer';
import {
  EsmRevenueHeadDetailReducerInitialState,
  esmRevenueHeadDetailReducerReducer,
} from './reducers/admin-esm-revenue-head-detail.reducer';
import {
  MdaRevenueHeadReducerInitialState,
  mdaRevenueHeadReducerReducer,
} from './reducers/admin-mda-revenue-head.reducer';
import {
  MdaRevenueHeadDetailReducerInitialState,
  mdaRevenueHeadDetailReducerReducer,
} from './reducers/admin-mda-revenue-head-detail.reducer';

export interface AppState {
  toast: ToastState;
  lookup: LookupState;
  bill: BillState;
  user: UserReducerInitialState;
  adminDashboard: AdminDashboardReducerInitialState;
  role: RoleReducerInitialState;
  invoice: InvoiceReducerInitialState;
  settings: SettingsReducerInitialState;
  adminInvoiceReport: InvoiceReportReducerInitialState;
  adminAnalytics: AnalyticsReducerInitialState;
  adminSettlement: SettlementReportReducerInitialState;
  adminCollection: CollectionReportReducerInitialState;
  adminMda: MdaReportReducerInitialState;
  adminExpertSystemModule: ExpertSystemModuleReducerInitialState;
  adminEsmRevenueHead: EsmRevenueHeadReducerInitialState;
  adminEsmRevenueHeadDetail: EsmRevenueHeadDetailReducerInitialState;
  adminMdaRevenueHead: MdaRevenueHeadReducerInitialState;
  adminMdaRevenueHeadDetail: MdaRevenueHeadDetailReducerInitialState;
}

export const reducers: ActionReducerMap<AppState> = {
  toast: toastReducer,
  lookup: lookupReducer,
  bill: billReducer,
  user: userReducer,
  adminDashboard: adminDashboardReducer,
  role: roleReducer,
  invoice: invoiceReducer,
  settings: settingsReducer,
  adminInvoiceReport: invoiceReportReducerReducer,
  adminAnalytics: analyticsReducerReducer,
  adminSettlement: settlementReportReducerReducer,
  adminCollection: collectionReportReducerReducer,
  adminMda: mdaReportReducerReducer,
  adminExpertSystemModule: expertSystemModuleReducerReducer,
  adminEsmRevenueHead: esmRevenueHeadReducerReducer,
  adminEsmRevenueHeadDetail: esmRevenueHeadDetailReducerReducer,
  adminMdaRevenueHead: mdaRevenueHeadReducerReducer,
  adminMdaRevenueHeadDetail: mdaRevenueHeadDetailReducerReducer,
};

export function localStorageSyncReducer(
  reducer: ActionReducer<AppState>
): ActionReducer<AppState> {
  return localStorageSync({
    keys: [
      {
        user: {
          encrypt: (state) => encrypt(state),
          decrypt: (state) => decrypt(state),
        },
      },
      {
        lookupReducer: {
          encrypt: (state) => encrypt(state),
          decrypt: (state) => decrypt(state),
        },
      },
      {
        role: {
          encrypt: (state) => encrypt(state),
          decrypt: (state) => decrypt(state),
        },
      },
      {
        settings: {
          encrypt: (state) => encrypt(state),
          decrypt: (state) => decrypt(state),
        },
      },
    ],
    rehydrate: true,
  })(reducer);
}

export const metaReducers: MetaReducer<AppState>[] =
  typeof window === 'undefined'
    ? []
    : isDevMode()
    ? [localStorageSyncReducer]
    : [localStorageSyncReducer];
