import { EffectsModule } from '@ngrx/effects';
import { UserEffects } from './user.effect';
import { AdminDashboardEffects } from './admin-dashboard.effect';
import { LookupEffects } from './lookup.effect';
import { AuthEffects } from './auth.effects';
import { RoleEffects } from './role.effect';
import { AdminInvoiceReportEffects } from './admin-invoice-report.effect';
import { AdminCollectionReportEffects } from './admin-collection-report.effect';
import { AdminAnalyticsEffects } from './admin-analytics.effect';
import { AdminSettlementReportEffects } from './admin-settlement-report.effect';
import { AdminMdaReportEffects } from './admin-mda-report.effect';
import { AdminExpertSystemModuleEffects } from './admin-expert-system-module.effect';
import { AdminEsmRevenueHeadEffects } from './admin-esm-revenue-head.effect';
import { AdminEsmRevenueHeadDetailEffects } from './admin-esm-revenue-head-detail.effect';
import { AdminMdaRevenueHeadEffects } from './admin-mda-revenue-head.effect';
import { AdminMdaRevenueHeadDetailEffects } from './admin-mda-revenue-head-detail.effect';

export const getEffects = () =>
  EffectsModule.forRoot([
    UserEffects,
    AdminDashboardEffects,
    AdminMdaReportEffects,
    AdminInvoiceReportEffects,
    AdminCollectionReportEffects,
    AdminSettlementReportEffects,
    AdminAnalyticsEffects,
    AdminExpertSystemModuleEffects,
    AdminEsmRevenueHeadEffects,
    AdminEsmRevenueHeadDetailEffects,
    AdminMdaRevenueHeadEffects,
    AdminMdaRevenueHeadDetailEffects,
    LookupEffects,
    AuthEffects,
    RoleEffects,
  ]);
