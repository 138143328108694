import { createReducer, on } from '@ngrx/store';
import { setStateAction } from '../actions/admin-collection-report.actions';
import { Action } from '../../models';
import dayjs from 'dayjs';

export interface Collection {
  taxPayerName: string;
  mda: string;
  createdAt: string;
  modifiedAt: string;
  revenueHead: string;
  id: string;
  taxOffice: string;
  invoiceNumber: string;
  amount: string;
}

export interface CollectionReportReducerInitialState {
  maxDate: string;
  minDate: string;
  status: string;
  rhId: string;
}

export const initialState: CollectionReportReducerInitialState = {
  maxDate: '',
  minDate: '',
  status: '',
  rhId: '',
};

const reducer = createReducer(
  initialState,
  on(setStateAction, (state, payload) => {
    return {
      ...state,
      ...payload,
    };
  })
);

export function collectionReportReducerReducer(
  state: CollectionReportReducerInitialState,
  action: Action
) {
  return reducer(state, action);
}
