import { createReducer, on } from '@ngrx/store';
import { setStateAction } from '../actions/admin-mda-report.actions';
import { Action } from '../../models';
import dayjs from 'dayjs';

export interface MdaReportReducerInitialState {
  maxDate: string;
  minDate: string;
}

export const initialState: MdaReportReducerInitialState = {
  maxDate: '',
  minDate: '',
};

const reducer = createReducer(
  initialState,
  on(setStateAction, (state, payload) => {
    return {
      ...state,
      ...payload,
    };
  })
);

export function mdaReportReducerReducer(
  state: MdaReportReducerInitialState,
  action: Action
) {
  return reducer(state, action);
}
